<template>
    <div class="container_con">
        <el-card>
            <div class="con_title"><span></span> 新增</div>
            <el-form ref="form" :rules="rules" :model="form" label-width="120px">
                <el-form-item label="标题：" prop="title">
                    <el-input v-model="form.title" maxlength="20" placeholder="请输入标题" style="width:300px" />
                </el-form-item>
                <el-form-item label="配置名称标识：" prop="name">
                    <el-input v-model="form.name" maxlength="20" placeholder="请输入配置名称标识" style="width:300px" />
                </el-form-item>
                <el-form-item label="值：" prop="value">
                    <el-input v-model="form.value" maxlength="30" placeholder="请输入值" style="width:300px" />
                </el-form-item>
                <el-form-item label="分组：" prop="groups">
                    <el-input v-model="form.groups" maxlength="30" placeholder="请输入分组" style="width:300px" />
                </el-form-item>
            </el-form>
        </el-card>
    </div>
    <div class="bottom_btn">
        <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
    </div>
</template>

<script>
    export default {
        name: "addPosition",
        data() {
            return {
                id: '',
                is_ajax: false,
                form: {
                    title:'',
                    remark:'',
                    sort: 0
                },
                rules: {
                  title: [
                    { required: true, message: "标题不能为空",trigger: "blur"}
                  ],
                  name: [
                    { required: true, message: "配置名称标识不能为空",trigger: "blur"}
                  ],
                  value: [
                    { required: true, message: "值不能为空",trigger: "blur"}
                  ],
                  groups: [
                    { required: true, message: "分组不能为空",trigger: "blur"}
                  ]
                },
                list: []
            };
        },
        created() {
            this.id=this.$route.query.id || '';
            if(this.id){
                this.get_data()
            }
        },
        methods: {
            // 表单重置
            reset() {
              this.form = {
                title:'',
                remark:'',
                sort:0
              };
              if(this.$refs['form']){
                this.$refs['form'].clearValidate();
              }
            },
            /** 提交按钮 */
            submitForm: function(form) {
              this.$refs[form].validate((valid)  => {
                if (valid) {
                  this.is_ajax=true;
                  if(form=='form'){
                    if(this.form.id){
                      this.$httpPost("/backend/SysConfig/save", this.form).then((res) => {
                          if (res.status == 200) {
                              this.$message.success('更新成功');
                              this.$router.go(-1)
                          } else {
                              this.$message.error(res.message);
                          }
                          this.is_ajax=false;
                      }).catch((err) => {
                          console.log(err);
                          this.is_ajax=false;
                      });
                    }else{
                        this.$httpPost("/backend/SysConfig/save", this.form).then((res) => {
                            if (res.status == 200) {
                                this.$message.success('新增成功');
                                // this.reset();
                                this.$router.go(-1)
                            } else {
                                this.$message.error(res.message);
                            }
                            this.is_ajax=false;
                        }).catch((err) => {
                            console.log(err);
                            this.is_ajax=false;
                        });
                    }
                  }
                }
              });
            },
            get_data(){
                this.$httpGet("/backend/SysConfig/read", {id:this.id}).then((res) => {
                    if (res.status == 200) {
                        this.form=res.data
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }
        }

    };
</script>

<style lang="scss" scoped></style>
